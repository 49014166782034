import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Sorry... We're not sure what you were looking for, but it's not here.</p>
    <p><Link to="/">Go back to the homepage</Link></p>
  </Layout>
)

export default NotFoundPage
